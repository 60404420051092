import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/careers/hero"
import OurMission from "./screens/careers/our-mission"
import KeyMetrics from "./screens/careers/key-metrics"
import LifeAtVisby from "./screens/careers/life-at-visby"
import PersonnelImages from "./screens/careers/personnel-images"
import Together from "./screens/careers/together"
import FindOpportunity from "./screens/careers/find-opportunity"

const Careers = () => {
  return (
    <React.Fragment>
      <Hero />
      <OurMission />
      <KeyMetrics />
      <LifeAtVisby />
      <PersonnelImages />
      <Together />
      <FindOpportunity />
    </React.Fragment>
  )
}
export default Careers

export const Head = () => (
  <Seo
    title="Careers"
    description="At Visby, everyone's voices and ideas are valued because we know that our sum is 
    greater when each teammate's whole self is brought to the table."
    image="/meta/careers.jpg"
  />
)
