import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  position: static;
  max-width: 1150px!important;
  margin: 0 auto;

  ${breakpoint.medium`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

const StyledTogether = styled.section`
  width: 100%;
  position: relative;
  padding: 55px 0;
  background-color: ${colors.grayLight};
  color: ${colors.charcoal};
  border-bottom-width: 0;
  overflow: hidden;

  ${breakpoint.large`
    padding: 120px 0;
  `}

  h2 {
    padding-bottom: 2rem;    
  } 
  
  p {
    padding-bottom: 2rem;
  }

  .together__content {
	max-width: initial;
	padding: 40px 0;

  ${breakpoint.medium`
		max-width: 330px;
  `}
    
  }

  .together__image {
    max-width: 800px;
    width: 100%;
    margin: 0px;    
    
  ${breakpoint.small`
    	margin-left: 20px;
  `}

    .gatsby-image-wrapper {
      width: 100%;
    }
  }

`

const Together = () => {

  return (
    <StyledTogether>
      <StyledContainer data-aos="fade-up">
        <div className="together__content" data-aos="fade-right" data-aos-duration="1000">
          <h2>Together, we are stronger</h2>
          <p>
				With a workforce that speaks more than 20 languages, heralding from over 30 countries, our whole is truly larger than the sum of our parts. Join us on our mission to bring the lab to the patient, wherever that may be. One thing we know, we’ll get there quicker with more perspectives at the table.  
          </p>
        </div>
        <div className="together__image" data-aos="fade-left" data-aos-duration="1000">
          <StaticImage
            src="../../../assets/images/careers/globe.png"
            width={800}
            alt="Together We Are Stronger"
          />
        </div>
      </StyledContainer>
    </StyledTogether>
  )
}

export default Together
